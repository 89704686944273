@screen sm {
    .prose a,
    .underlined {
       text-decoration: none;
       @apply
          relative;
       &:before {
          @apply
             w-0
             bg-current
             absolute
             left-auto
             right-0
             transition-width
             duration-200
             ease-linear;
          content: '';
          height: rem(1);
          bottom: rem(1);
       }
       &--large {
         &:before {
            height: rem(2);
         }
      }
       &--visible {
          &:before {
             @apply
                w-full;
          }
       }
       &:hover {
          &:before {
             @apply
                w-full
                left-0
                right-auto;
          }
       }
    }
    .prose a {
       &:before {
          height: rem(1);
          bottom: rem(0);
       }
    }
  }
