/* purgecss start ignore */
#nearly-there-form {
  label {
    cursor: pointer;
    input:checked ~ span {
      @apply bg-carbon 
              text-white 
              scale-95;
    }
  }
}
/* purgecss end ignore */
