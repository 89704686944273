.Faq__Answer{
  height: auto;
  max-height: 0;
  overflow: hidden;
}

.Faq__QuestionWrap[data-open="true"] {
  h3 .fa {
    transform: rotate(180deg);
  }
  .Faq__Answer{
    max-height: 400rem;
  }
}