.lazy-load {
    @apply
        transition
        opacity-0;
    &--loaded {
        @apply
          opacity-100
          delay-100;
    }
}

.no-js {
    .lazy-load {
        opacity: 1;
    }
}
