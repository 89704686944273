// Font Face
// @include font-face("stratos", "stratosweb-regular", normal, normal);
@include font-face("stratos", "stratosweb-medium", 500, normal);
@include font-face("stratos", "stratosweb-semibold", 600, normal);
// @include font-face("stratos", "stratosweb-bold", 700, normal);
@include font-face("stratos", "stratosweb-semilight", normal, normal);

@include font-face("inter", "Inter-Regular", normal, normal);
@include font-face("inter", "Inter-SemiBold", 600, normal);

// Body

html {
  @apply text-base;
}

body {
  @apply font-sans-primary
     text-gray-800
     text-optimize-legibility
     antialiased;
}

// Links

a {
  @apply transition;
}

// Typography

p {
  @apply mb-4;
  &:last-of-type {
    @apply mb-0;
  }
  &:empty {
    @apply hidden;
  }
}

// form
#nearly-there-form label input:checked ~ span {
  @apply bg-carbon
      text-white
      scale-95;
}

// Selection

::selection {
  @apply text-primary-500
      bg-primary-100;
}

// FOUT fix

.wf-loading {
  @apply invisible;
}

.wf-active,
.wf-inactive {
  @apply visible;
}

.hlist-text {
  a {
    --tw-text-opacity: 1;
    color: rgba(66, 133, 244, var(--tw-text-opacity));
    font-weight: 500;
  }
}

// Menu Fix
// Tailwinds scales dont include anything big enough....
.max-h-menu {
  max-height: 60em;
}

// Fix for Headings with <strong>
h1, h2, h3, h4, h5, h6{
  &.font-semibold {
    strong,
    .font-medium{
      font-weight: 600;
    }
  }
}