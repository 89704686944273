@screen sm {
    .prose a,
    .underlined-multiline {
      text-decoration: none;
      span, h1, h2, h3, h4, h5, h6 {
         background-image: linear-gradient(currentColor, currentColor);
         background-position: 0% 100%;
         background-repeat: no-repeat;
         background-size: 0% rem(2);
         transition: background-size .5s;
      }
      &--sm {
         span, h1, h2, h3, h4, h5, h6 {
            background-size: 0% rem(1);
         }
         &:hover, &:focus {
            span, h1, h2, h3, h4, h5, h6 {
               background-size: 100% rem(1);
            }
         }
      }
      &:hover, &:focus {
         span, h1, h2, h3, h4, h5, h6 {
            background-size: 100% rem(2);
         }
      }
    }
  }
