/* purgecss start ignore */

.pricing-title h1 span,
.pricing-title h2 span{
  position: relative;
  &::after{
    content: "";
    display: block;
    width: 122px;
    height: 24px;
    background-size: 100%;
    position: absolute;
    bottom: 3px;
    left: 0;
    transform: translate(-25%,100%);
    pointer-events: none;
  }
}

.includesList{
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    padding-left: 2em !important;
    margin-left: 0 !important;
  }
  li:before {
    content: "\f00c"; /* FontAwesome Unicode */
    font-family: FontAwesome;
    display: inline-block;
    margin-left: -1.3em; /* same as padding-left set on li */
    width: 2em; /* same as padding-left set on li */
  }
}

.StartTrialButton{
  position: relative;
  &::after{
    content: "";
    display: block;
    width: 123px;
    height: 82px;
    background-size: 100%;
    position: absolute;
    top: 16px;
    right: 23px;
    transform: translate(100%,-100%);
    pointer-events: none;
  }
}


/*Range Reset*/
input[type="range"] {
  -webkit-appearance: none;
   appearance: none;
   background: transparent;
   cursor: pointer;
   width: 100%;
   height: 12px;
   border-radius: 6px;
   margin-top: 3em;
}

/* Removes default focus */
input[type="range"]:focus {
 outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
  background-color: rgba(28, 28, 28, 0.08);
  border-radius: 6px;
  height: 12px;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
 -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -20px; /* Centers thumb on the track */

  /*custom styles*/
  background-color: #fff;
  width: 50px;
  height: 50px;
  border: solid 12px #000;
  border-radius: 9999em;
}

/******** Firefox styles ********/
/* slider track */
input[type="range"]::-moz-range-track {
  background-color: rgba(28, 28, 28, 0.08);
  border-radius: 6px;
  height: 12px;
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
  /*custom styles*/
  background-color: #fff;
  width: 26px;
  height: 26px;
  border: solid 12px #000;
  border-radius: 9999em;
}

// Breakdown List
.breakdown-list li{
  position: relative;
  padding-left: 15px;

  &::before{
    content: "–";
    position: absolute;
    top: 0;
    left: 0;
  }
}

// currencySymbol
.currencySymbol{
  font-size: 60% !important;
  top: -.4em !important;
}




// New Pricing Page

.pricingRadio{
  width: 26px;
  height: 26px;
  border-width: 3px !important;
  &:after{
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    background-color: transparent;
    transform: translate(-50%,-50%);
  }
}

.pricingType--selected{
  .pricingTypeName{
    color: #007AFF;
  }
  .pricingRadio{
    border-color: #007AFF;
    &::after{
      background-color: #007AFF;
    }
  }
}


// Selected State for plans
.pricingPlan--selected {
  background-color: #000;
  color: #fff;
  span, h3, h4 {
    color: #fff;
  }

  p,
  ul li span.text-base,
  .planFrequency {
    color: #D3D8DD;
  }


  div, .pricingRadio {
    border-color: #fff;
  }
  .pricingTrial,
  .pricingRadio::after{
    background-color: #fff;
  }
  .pricingTrial{
    color: #000;
  }
}



.planGrid{
  display: grid;
  grid-template-columns: 1;
  gap: 2rem;
}

.pricingButton{
  border-width: 3px !important;
}

sup.pricingSymbol,
.pricingSymbol {
  font-size: 50% !important;
  top: -0.9em !important;
}


.pricingPanel {
  border-radius: 48px !important;
  padding: 44px !important;
}

/* purgecss end ignore */