.link {
    @apply transition;
    &--primary {
        @apply 
            text-primary-500
            underline;
        &:hover,
        &:active {
            @apply text-primary-700;
        }
    }
}